import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { BotRequest } from '../../../types/botRequest.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const AdminChatBot = () => {
    const user = useContext(UserContext)
    const [botRequests, setBotRequests] = useState<BotRequest[] | undefined>(undefined)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentlang, setCurrentLang] = useState(lng)

    const tableHeader = ['#', 'Question/Phrase', 'Réponse', 'Actions', '', '']

    useEffect(() => {
        verifyToken()
        if (!botRequests || currentlang !== lng) {
            setCurrentLang(lng)
            axios
                .get(`${SERVER_URL}/bot-request`, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setBotRequests(
                        data.map((botRequestItem: BotRequest) => ({
                            id: botRequestItem.id,
                            question: botRequestItem.question,
                            answer: botRequestItem.answer,
                            action: botRequestItem.action,
                            edit: `${lng}/bva-admin/bva-bot/${botRequestItem.id}`,
                            remove: true,
                        }))
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [botRequests, user])

    if (!botRequests) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/bot-request/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de cette question est réussi').then(() => {
                    setBotRequests([...botRequests.filter((botRequest) => botRequest.id !== res.data.id)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>BVA bot</Title>
                <LinkBtn to={`/${lng}/bva-admin/bva-bot/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={botRequests} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export default AdminChatBot
