import styled from '@emotion/styled'
import { ReactNode, useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'
import './tooltipBva.css'

const TooltipBva = ({
    children,
    tootipText,
    placement,
}: {
    children: ReactNode
    tootipText: ReactNode
    placement?: Placement
}) => {
    const [show, setShow] = useState(false)
    const tooltipContainerRef = useRef(null)

    return (
        <TooltipContainer
            ref={tooltipContainerRef}
            onMouseOver={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            {children}
            <TooltipOverlay target={tooltipContainerRef.current} show={show} placement={placement || 'bottom'}>
                <Tooltip>{tootipText}</Tooltip>
            </TooltipOverlay>
        </TooltipContainer>
    )
}

const TooltipContainer = styled.div`
    display: inline-flex;
    justify-content: flex-start
    align-items: center;
    position: relative;
    cursor: pointer;
`

const TooltipOverlay = styled(Overlay)`
    display: flex;
    justify-items: center;
`

export default TooltipBva
