import { useTranslation } from 'react-i18next'
import Title from '../../components/global/Title'
import { useVerifyAdminToken } from '../../hooks/useVerifyAdminToken'

const AdminDashboard = () => {
    const { t } = useTranslation('translation')
    const verifyToken = useVerifyAdminToken()

    verifyToken()
    return (
        <>
            <Title>{t('admin.dashboard')}</Title>
        </>
    )
}

export default AdminDashboard
