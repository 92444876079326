import axios from 'axios'
import { SERVER_URL, apiHeader } from '../config'
import { serverFile } from '../services/file.service'
import { DocumentModel } from '../types/document.type'

export const getAll = async (lng: string) => {
    const tokenAdmin = localStorage.getItem('tokenAdmin')!

    const data = (await axios.post(`${SERVER_URL}/documents/all`, { lng }, apiHeader(tokenAdmin)))
        .data as DocumentModel[]
    return data.map((doc) => ({ ...doc, file: doc.file.includes('http') ? doc.file : serverFile(doc.file) }))
}

export const getAllMemberDocuments = async (lng: string) => {
    const tokenMember = localStorage.getItem('tokenMember') ?? ''

    const data = (await axios.post(`${SERVER_URL}/documents/all/member`, { lng }, apiHeader(tokenMember)))
        .data as DocumentModel[]
    return data.map((doc) => ({ ...doc, file: doc.file.includes('http') ? doc.file : serverFile(doc.file) }))
}

export const getAllAdminDocuments = async (lng: string) => {
    const tokenAdmin = localStorage.getItem('tokenAdmin')!

    const data = (await axios.post(`${SERVER_URL}/documents/all/admin`, { lng }, apiHeader(tokenAdmin)))
        .data as DocumentModel[]
    return data.map((doc) => ({ ...doc, file: doc.file.includes('http') ? doc.file : serverFile(doc.file) }))
}

export const deleteDocumentById = async (id: number) => {
    const tokenAdmin = localStorage.getItem('tokenAdmin')!

    const data = (await axios.delete(`${SERVER_URL}/documents/${id}`, apiHeader(tokenAdmin))).data
    return data
}
