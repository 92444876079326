import styled from '@emotion/styled'
import Breakpoint from '../../Breakpoint'

const AdminContainer = styled.div`
    width: 85%;
    padding: 24px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        padding: 24px 2% 24px 2%;
    }
`

export default AdminContainer
