import styled from '@emotion/styled'
import axios from 'axios'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { BotRequest } from '../../../types/botRequest.type'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const urlRegex = /https?:\/\/[a-z0-9\/:%_+.,#?!@&=-]+/

const AdminChatBotForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [action, setAction] = useState<{ redirection: string }>({ redirection: '' })
    const [isLoading, setIsLoading] = useState(true)

    const [isRedirectionSwitch, setIsRedirectionSwitch] = useState(false)

    const isAddForm = location.pathname.includes('/bva-admin/bva-bot/creer')

    useEffect(() => {
        verifyToken()
        if (!isAddForm && isLoading) {
            axios
                .get(`${SERVER_URL}/bot-request/${params.id}`, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setQuestion(res.data.question)
                    setAnswer(res.data.answer)
                    setAction(res.data.action ? res.data.action : { redirection: '' })
                    setIsRedirectionSwitch(!!res.data.action.redirection)
                    setIsLoading(false)
                })
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id])

    if (isLoading) return <Loader />

    const isValidate = question && answer

    const submit = () => {
        verifyToken()
        const data: BotRequest = {
            question,
        }
        if (answer) data.answer = answer
        data.action = action && isRedirectionSwitch ? action : { redirection: '' }
        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/bot-request`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`La question a bien été créé.`).then(() => {
                        navigate('/bva-admin/bva-bot')
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            axios
                .patch(`${SERVER_URL}/bot-request/${params.id}`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`La question a bien été modifié.`).then(() => {
                        navigate('/bva-admin/bva-bot')
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} une question/phrase</Title>
                <StyledButtonLink to={`/${lng}/bva-admin/bva-bot`} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Question/Phrase *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Question"
                            onChange={(e) => setQuestion(e.target.value)}
                            value={question}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Réponse *</Label>
                        <Form.Control
                            type="text"
                            placeholder="Réponse"
                            onChange={(e) => setAnswer(e.target.value)}
                            value={answer}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Action</Label>
                        <VerticalFlexRedirection>
                            <BootstrapSwitchButton
                                onlabel="Redirection On"
                                offlabel="Redirection Off"
                                checked={isRedirectionSwitch}
                                style="switch-redirection"
                                onChange={(checked: boolean) => setIsRedirectionSwitch(checked)}
                            />
                            {isRedirectionSwitch && (
                                <Form.Control
                                    type="text"
                                    placeholder="Lien de redirection"
                                    onChange={(e) => setAction({ ...action, redirection: e.target.value })}
                                    value={action?.redirection}
                                />
                            )}
                        </VerticalFlexRedirection>
                    </Form.Group>

                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>
        </>
    )
}

const VerticalFlexRedirection = styled.div`
    display: flex;
    flex-direction: column;

    & .switch-redirection {
        width: 200px;
        margin-bottom: 8px;
    }
`

export default AdminChatBotForm
