import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import { useLangSelected } from '../../../hooks/useLangSelected'
import Header, { ExternalLink } from '../header/Header'
import MainLink from '../MainLink'
import Main from './Main'

const UserInterface = ({ children }: { children: ReactNode }) => {
    let lng = useLangSelected()

    const { t } = useTranslation()

    return (
        <div>
            <Header />
            <Main>{children}</Main>
            <Footer>
                <p>
                    {t('footer.copyright')}&nbsp;
                    <a href="https://www.linkedin.com/in/melvin-cado/" target="_blank" rel="noreferrer">
                        <strong>Melvin Cado</strong>
                    </a>{' '}
                    -
                    <Link to={`/${lng}/bva-admin`}>
                        <strong>{t('footer.administration')}</strong>
                    </Link>
                </p>
            </Footer>
        </div>
    )
}

const ExternalIcon = styled(FontAwesomeIcon)`
    margin-left: 6px;
    height: 12px;
    position: relative;
    top: -8px;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin: 0;
    }
`

const SidebarLink = styled(MainLink)`
    display: flex;
    align-items: center;

    &.active {
        border: none;
    }

    & * {
        margin-right: 8px;

        @media (max-width: ${Breakpoint.TABLET_L}) {
            justify-content: center;
            margin-right: 0;
        }
    }

    &:active {
        transform: scale(0.95);
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        justify-content: center;
    }
`

const SidebarExternalLink = styled(ExternalLink)`
    display: flex;
    align-items: center;

    &.active {
        border: none;
    }

    & * {
        margin-right: 8px;
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        justify-content: center;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 15%;
    background-color: white;
    min-height: calc(100vh - 166px);
    box-shadow: 0px 0px 4px black;
    box-sizing: border-box;
    padding: 16px;
    padding-left: 3%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        padding: 24px 0;
    }
`

const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: white;
    color: black;
    min-height: 58px;
    font-family: 'Roboto';
    box-shadow: 0px 0px 4px black;
    position: relative;
    z-index: 2;

    & * {
        font-size: 2rem;
    }

    & p {
        margin: 0;
    }

    & a {
        color: var(--primary-color);
        text-decoration: none;
    }

    & strong {
        font-family: 'Roboto Semi Bold';
        border-bottom: 1px solid var(--primary-color);
    }
`

export default UserInterface
