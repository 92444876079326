import styled from '@emotion/styled'
import Breakpoint from '../../Breakpoint'

const Container = styled.div`
    padding: 24px 12% 24px 12%;
    box-sizing: border-box;
    position: relative;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        padding: 24px 2% 24px 2%;
    }
`

export default Container
