import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import images from '../../../assets/img/images'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { NewsModel, NewsType, helperNewsType } from '../../../types/news.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const AdminNews = () => {
    const user = useContext(UserContext)
    const [news, setNews] = useState<NewsModel[] | undefined>(undefined)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const [currentlang, setCurrentLang] = useState(lng)

    const tableHeader = ['#', 'Titre', 'Description', 'Type', 'Image', 'Lien', '', '']

    useEffect(() => {
        verifyToken()
        if (!news || currentlang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/news/all`, { lng }, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setNews(
                        data.map((newsItem: NewsModel) => ({
                            id: newsItem.id,
                            title: newsItem.title,
                            description: ReactHtmlParser(newsItem.description),
                            type: helperNewsType(newsItem.type),
                            image:
                                newsItem.type === NewsType.MEETING
                                    ? images.reunion
                                    : newsItem.image
                                    ? serverFile(newsItem.image)
                                    : '',
                            link: newsItem.link,
                            edit: `/${lng}/bva-admin/articles/${newsItem.id}`,
                            remove: true,
                        }))
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [news, user, lng])

    if (!news) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/news/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de cette article est réussi').then(() => {
                    setNews([...news.filter((news) => news.id !== res.data.id)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Articles</Title>
                <LinkBtn to={`/${lng}/bva-admin/articles/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={news} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export default AdminNews
