import styled from '@emotion/styled'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../Breakpoint'
import images from '../../assets/img/images'
import Container from '../../components/global/Container'
import Loader from '../../components/global/Loader'
import News from '../../components/global/News'
import Title from '../../components/global/Title'
import { SERVER_URL } from '../../config'
import { useLangSelected } from '../../hooks/useLangSelected'
import { serverFile } from '../../services/file.service'
import { NewsModel } from '../../types/news.type'

const Home = () => {
    const [news, setNews] = useState<NewsModel[] | undefined>(undefined)

    const lng = useLangSelected()

    const loadNews = () =>
        axios.post(`${SERVER_URL}/news/public`, { lng }).then(({ data }) => {
            setNews(data)
        })

    const { t } = useTranslation('translation')

    useEffect(() => {
        if (!news) {
            loadNews()
        }
    }, [news])

    useEffect(() => {
        loadNews()
    }, [lng])

    return (
        <>
            <CoverImageContainer>
                <TextContainer>
                    <MainTitle data-cy="Title">{t('home.main-title')}</MainTitle>
                    <MainDescription data-cy="Description">
                        {t('home.presentation-1')}
                        <br />
                        {t('home.presentation-2')} <StrongWord>{t('home.presentation-3')}</StrongWord>{' '}
                        {t('home.presentation-4')}
                    </MainDescription>
                </TextContainer>
            </CoverImageContainer>

            <Container>
                <Title>{t('home.last-news')}</Title>
                <CardContainer>
                    {news ? (
                        news.map((news: NewsModel) => (
                            <News
                                key={news.id}
                                title={news.title}
                                type={news.type}
                                description={news.description}
                                image={serverFile(news.image)}
                                link={news.link}
                            />
                        ))
                    ) : (
                        <Loader />
                    )}
                </CardContainer>
            </Container>
        </>
    )
}

const StrongWord = styled.strong`
    font-size: 3rem;
    text-align: center;
    font-family: 'Roboto';
    font-weight: inherit;
`

const CoverImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: url(${images.coverPicture});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 728px;
`

const TextContainer = styled.div`
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #0007;
    padding: 0 10%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        padding: 0 2%;
    }
`

const MainTitle = styled.h1`
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 4.5rem;
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Roboto Semi Bold';
`

const MainDescription = styled.p`
    width: 100%;
    color: white;
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    text-align: center;
    font-family: 'Roboto';
`

export const CardContainer = styled.div`
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        display: flex;
        flex-direction: column;
    }

    & > * {
        align-self: center;
        justify-self: center;
    }
`

export default Home
