import Swal, { SweetAlertIcon } from 'sweetalert2'
import './alert.css'

const iconsType = {
    success: 'success',
    error: 'error',
}

const sweetAlert = (title: string, text: string, icon: SweetAlertIcon | undefined) =>
    Swal.fire({
        title,
        text,
        icon,
    })

export const sweetAlertError = (text: string) =>
    Swal.fire({
        title: 'Erreur',
        text,
        icon: 'error',
    })

export const sweetAlertSuccess = (text: string) =>
    Swal.fire({
        title: 'Succès',
        text,
        icon: 'success',
    })

export default sweetAlert
