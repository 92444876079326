import React from 'react'
import Td from './Td'

type TrType = {
    dataItem: unknown[]
    deleteById?: (id: number) => void
    id: number
}

const Tr = ({ dataItem, deleteById, id }: TrType) => {
    return (
        <tr>
            {Object.entries(dataItem).map((singleData, index) => (
                <Td key={`td${index}`} singleData={singleData} id={id} deleteById={deleteById} />
            ))}
        </tr>
    )
}

export default Tr
