import styled from '@emotion/styled'
import ImageUploader from 'react-images-upload'
import Breakpoint from '../../Breakpoint'

const Uploader = ({ onDrop }: { onDrop: (image: File[]) => void }) => {
    return (
        <CustomUploader
            className="form-control"
            label="Taille limite: 5mb. Les fichiers acceptés sont .jpg, .gif, .png, .svg"
            withLabel
            fileSizeError="Ce fichier est trop lourd"
            buttonText="Choisir une image"
            fileTypeError="Ce type de fichier n'est pas accepté."
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.svg']}
            maxFileSize={5242880}
            withPreview
            singleImage
        />
    )
}

const CustomUploader = styled(ImageUploader)`
    & .fileContainer {
        box-shadow: none;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        & .uploadPicturesWrapper {
            width: 300%;
        }
    }
`

export default Uploader
