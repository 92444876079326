import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Form, InputGroup } from 'react-bootstrap'
import Label from '../../../../components/form/Label'
import FlexColumn from '../../../../components/global/FlexColumn'

type MemberFieldProps = {
    isAddForm: boolean
    tournamentMoney: string
    setTournamentMoney: (value: string) => void
    setFirstname: (value: string) => void
    firstname: string
    setLastname: (value: string) => void
    lastname: string
    gender: string
    setGender: (value: string) => void
    dayOfBirth: string | null
    setDayOfBirth: (value: string | null) => void
    setTel: (value: string) => void
    tel: string
    setLicenseNumber: (value: string) => void
    licenseNumber: string
}

const MemberField = ({
    isAddForm,
    tournamentMoney,
    setTournamentMoney,
    setFirstname,
    firstname,
    setLastname,
    lastname,
    gender,
    setGender,
    dayOfBirth,
    setDayOfBirth,
    setTel,
    tel,
    setLicenseNumber,
    licenseNumber,
}: MemberFieldProps) => {
    return (
        <>
            {!isAddForm && (
                <Form.Group className="mb-3">
                    <Label>Compte tournoi *</Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Compte tournoi"
                            onChange={(e) => setTournamentMoney(e.target.value)}
                            value={tournamentMoney}
                        />
                        <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            )}
            <Form.Group className="mb-3">
                <Label>Prénom *</Label>
                <Form.Control
                    type="text"
                    placeholder="Prénom"
                    onChange={(e) => setFirstname(e.target.value)}
                    value={firstname}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Label>Nom d'usage *</Label>
                <Form.Control
                    type="text"
                    placeholder="Nom d'usage"
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Label>Sexe *</Label>
                <Form.Select defaultValue={gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="placeholder" disabled>
                        Sélectionne le sexe
                    </option>
                    <option value="H">Homme</option>
                    <option value="F">Femme</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <FlexColumn>
                    <Label>Date de naissance</Label>
                    <DatePicker
                        label="Date de naissance"
                        value={dayjs(dayOfBirth).format()}
                        onChange={(newValue) => {
                            setDayOfBirth(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                        inputFormat="dd/MM/yyyy"
                    />
                </FlexColumn>
            </Form.Group>
            <Form.Group className="mb-3">
                <Label>Numéro de téléphone</Label>
                <Form.Control
                    type="text"
                    placeholder="Numéro de téléphone"
                    onChange={(e) => setTel(e.target.value)}
                    value={tel}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Label>Numéro de licence *</Label>
                <Form.Control
                    type="text"
                    placeholder="Numéro de licence"
                    onChange={(e) => setLicenseNumber(e.target.value)}
                    value={licenseNumber}
                />
            </Form.Group>
        </>
    )
}

export default MemberField
