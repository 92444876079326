import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Title from '../../components/global/Title'
import Planning from '../../components/planning/Planning'
import Prices from '../../components/planning/Prices'

const HEADER_HEIGHT = 107
const NAVBAR_HEIGHT = 50
const SCROLL_MARGIN_TOP = 16

const scrollHeightDefault = HEADER_HEIGHT + NAVBAR_HEIGHT + SCROLL_MARGIN_TOP

const PlanningPage = () => {
    const [holidayName, setHolidayName] = useState('')
    const [mainName, setMainName] = useState('')
    const mainPlanningRef = useRef<null | HTMLDivElement>(null)
    const holidayPlanningRef = useRef<null | HTMLDivElement>(null)
    const tryTextRef = useRef<null | HTMLDivElement>(null)
    const tarifTextRef = useRef<null | HTMLDivElement>(null)

    const [navBarSelected, setNavBarSelected] = useState('')

    const { t } = useTranslation('translation')

    useEffect(() => {
        if (navBarSelected === '') {
            setNavBarSelected(holidayName ?? mainName)
        }
    }, [holidayName, mainName])

    return (
        <Container>
            {window.innerWidth > 904 && (
                <NavBar>
                    {holidayName && (
                        <Nav.Item>
                            <Nav.Link
                                active={navBarSelected === holidayName}
                                onClick={() => {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: (holidayPlanningRef.current?.offsetTop as number) - scrollHeightDefault,
                                    })
                                    setNavBarSelected(holidayName)
                                }}
                            >
                                {holidayName}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {mainName && (
                        <Nav.Item>
                            <Nav.Link
                                active={navBarSelected === mainName}
                                onClick={() => {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: (mainPlanningRef.current?.offsetTop as number) - scrollHeightDefault,
                                    })
                                    setNavBarSelected(mainName)
                                }}
                            >
                                {mainName}
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    <Nav.Item>
                        <Nav.Link
                            active={navBarSelected === t('page.price.title-try')}
                            onClick={() => {
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: (tryTextRef.current?.offsetTop as number) - scrollHeightDefault,
                                })
                                setNavBarSelected(t('page.price.title-try'))
                            }}
                        >
                            {t('page.price.title-try')}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={navBarSelected === t('page.price.title')}
                            onClick={() => {
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: (tarifTextRef.current?.offsetTop as number) - scrollHeightDefault,
                                })
                                setNavBarSelected(t('page.price.title'))
                            }}
                        >
                            {t('page.price.title')}
                        </Nav.Link>
                    </Nav.Item>
                </NavBar>
            )}

            <Title
                style={{
                    textAlign: 'center',
                }}
            >
                {t('page.price.warning-off-day')}
            </Title>

            <Planning
                holidayPlanningRef={holidayPlanningRef}
                holidayName={holidayName}
                setHolidayName={setHolidayName}
                mainPlanningRef={mainPlanningRef}
                mainName={mainName}
                setMainName={setMainName}
            />
            <Prices tryTextRef={tryTextRef} tarifTextRef={tarifTextRef} />
        </Container>
    )
}

const NavBar = styled(Nav)`
    position: fixed;
    width: 100vw;
    display: flex;
    padding-left: 3%;
    z-index: 4;
    left: 0;
    top: 108px;
    background: white;
    box-shadow: 0 0 3px;
    height: ${NAVBAR_HEIGHT}px;

    & .nav-item {
        margin-right: 30px;
    }

    & .nav-link {
        background: none !important;
        padding: 8px 0;
        text-decoration: none;
        color: black;
        font-size: 2rem;
        font-family: 'Roboto' !important;

        &:hover {
            color: var(--primary-color);
        }

        &.active {
            color: var(--primary-color);
            border-bottom: 5px solid var(--primary-color);
        }

        &:active {
            transform: scale(0.95);
        }
    }
`

const Container = styled.div`
    padding: 58px 3% 24px 3%;
    overflow: auto hidden;
`

export default PlanningPage
