import { jwtDecode } from 'jwt-decode'

export function isTokenExpired(token: string): boolean {
    const decodedToken: any = jwtDecode(token)
    let result: boolean = true

    if (decodedToken && decodedToken.exp) {
        const expirationDate: Date = new Date(0)
        expirationDate.setUTCSeconds(decodedToken.exp)
        result = expirationDate.valueOf() < new Date().valueOf()
    }

    return result
}
