import Container from '../../components/global/Container'
import Title from '../../components/global/Title'

const Page404 = () => {
    return (
        <Container>
            <Title>Erreur 404 - page introuvable</Title>
        </Container>
    )
}

export default Page404
