import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllMemberDocuments } from '../../api/documentation.api'
import Document from '../../components/global/Document'
import Loader from '../../components/global/Loader'
import SearchInput from '../../components/global/SearchInput'
import Title from '../../components/global/Title'
import { useLangSelected } from '../../hooks/useLangSelected'
import { useVerifyMemberToken } from '../../hooks/useVerifyMemberToken'
import { serverFile } from '../../services/file.service'
import { DocumentModel } from '../../types/document.type'
import { CardContainer } from '../global/Home'

const MemberDocumentation = () => {
    const { t } = useTranslation('translation')

    const verifyToken = useVerifyMemberToken()
    const lng = useLangSelected()

    const [searchTerm, setSearchTerm] = useState('')
    const [filterDocuments, setFilterDocuments] = useState<DocumentModel[]>([])

    const { data: documents } = useQuery({
        queryKey: ['document-member-all'],
        queryFn: () => {
            verifyToken()
            return getAllMemberDocuments(lng)
        },
    })

    useEffect(() => {
        setFilterDocuments(
            documents?.filter((doc) => doc.title.toLowerCase().includes(searchTerm.toLowerCase())) as DocumentModel[]
        )
    }, [searchTerm, documents])

    return (
        <>
            <Title>{t('member.documentation')}</Title>
            <SearchInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('member.search-document-input')}
            />
            <CardContainer>
                {filterDocuments?.map((document: DocumentModel) => (
                    <Document
                        key={document.id}
                        title={document.title}
                        description={document.description}
                        link={document.file.includes('http') ? document.file : serverFile(document.file)}
                    />
                )) ?? <Loader />}
            </CardContainer>
        </>
    )
}

export default MemberDocumentation
