import styled from '@emotion/styled'
import { faCircleInfo, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Breakpoint from '../../../Breakpoint'
import images from '../../../assets/img/images'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import TooltipBva from '../TooltipBva'
import { MainHeaderNav } from './MainNav'

export const disconnect = () => {
    if (location.pathname.includes('bva-admin')) localStorage.removeItem('tokenAdmin')
    else if (location.pathname.includes('bva-membre')) localStorage.removeItem('tokenMember')
    window.location.reload()
}

const Header = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const langSelected = useLangSelected()
    const [selectLng, setSelectLng] = useState(langSelected.toUpperCase())
    const { t, i18n } = useTranslation('translation')

    const [selectedLngLowerCase, setSelectedLngLowerCase] = useState(langSelected)

    const navigate = useNavigate()

    return (
        <HeaderCustom>
            <LogoLink to={`/${selectedLngLowerCase}/`}>
                <img src={images.logoBVA} alt="Logo du BVA" />
            </LogoLink>

            <ReactFlagsSelectCustom
                selected={selectLng}
                customLabels={{
                    FR: t('global.lang.fr'),
                    GB: t('global.lang.gb'),
                    BR: t('global.lang.br'),
                }}
                onSelect={(code) => {
                    setSelectLng(code)
                    setSelectedLngLowerCase(code.toLowerCase())

                    const pathnameUpdated = location.pathname
                        .split('/')
                        .filter((x, i) => i !== 1)
                        .join('/')

                    i18n.changeLanguage(code.toLowerCase()).then(() => {
                        navigate(`/${code.toLowerCase()}${pathnameUpdated}`, { replace: true })
                    })
                }}
                countries={['FR', 'GB', 'BR']}
                showSelectedLabel={false}
            />

            {(!location.pathname.includes('/bva-admin') && !location.pathname.includes('/bva-membre')) ||
            location.pathname === `/${langSelected}/bva-admin/login` ||
            location.pathname === `/${langSelected}/bva-membre/login` ? (
                <MainHeaderNav selectedLngLowerCase={selectedLngLowerCase} />
            ) : (
                <>
                    {location.pathname.includes('/bva-membre') && (
                        <HeaderTournamentMoneyContent>
                            <TournamentMoneyContainer>
                                <TournamentMoneyHeader>
                                    <TournamentMoneyTitle>{t('header.my-tournament-account')}</TournamentMoneyTitle>
                                    <TooltipBva
                                        tootipText={
                                            <div
                                                style={{
                                                    width: 250,
                                                    padding: '1rem',
                                                    background: 'black',
                                                    borderRadius: 8,
                                                }}
                                            >
                                                {t('header.tournament-account-message')}
                                            </div>
                                        }
                                    >
                                        <FontAwesomeIconInfo icon={faCircleInfo} />
                                    </TooltipBva>
                                </TournamentMoneyHeader>
                                <TournamentMoneyContent>{user?.memberData?.tournamentMoney}€</TournamentMoneyContent>
                            </TournamentMoneyContainer>
                        </HeaderTournamentMoneyContent>
                    )}

                    <DisconnectButton>
                        <FontAwesomeIcon icon={faPowerOff} onClick={() => disconnect()} />
                    </DisconnectButton>
                </>
            )}
        </HeaderCustom>
    )
}

const ReactFlagsSelectCustom = styled(ReactFlagsSelect)`
    height: min-content;
    align-self: center;
    display: flex;
    position: relative;
    align-items: center;

    & ul {
        width: max-content;
        top: 46px;
    }
`

const FontAwesomeIconInfo = styled(FontAwesomeIcon)`
    align-self: center;
`

const TournamentMoneyTitle = styled.span`
    margin-right: 8px;
`

const TournamentMoneyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-border-top-left-radius: 16px;
    border-border-top-right-radius: 16px;
`

const TournamentMoneyHeader = styled.div`
    display: flex;
    background-color: var(--primary-color);
    color: white;
    padding: 8px 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`

const TournamentMoneyContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    color: var(--primary-color);
    border: solid 1px var(--primary-color);
    padding: 8px 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
`

const HeaderTournamentMoneyContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    align-items: flex-end;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        scale: 0.8;
    }
`

export const ExternalLink = styled.a`
    padding: 8px 0;
    text-decoration: none;
    color: black;
    font-size: 2rem;
    transition: scale 0.4s;
    font-family: 'Roboto' !important;

    &:hover {
        color: var(--primary-color);
        scale: 1.2;
    }

    &.active {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
    }
`

const DisconnectButton = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-left: 8px;

    & .fa-power-off {
        height: 50px;
        color: var(--primary-color);
        cursor: pointer;
    }

    &:active {
        transform: scale(0.95);
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        & .fa-power-off {
            width: 40px;
            height: 40px;
        }
    }
`
const HeaderCustom = styled.header`
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background: white;
    height: 108px;
    padding: 4px;
    box-shadow: 0px 1px 4px black;
    z-index: 5;
`

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    width: 100px;
    margin-left: 2%;
    margin-right: 8px;

    & span {
        display: flex;
        align-items: center;
    }

    & img {
        width: 100px;
        height: 100px;
    }

    &:active {
        transform: scale(0.95);
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        & img {
            width: 70px;
            height: 70px;
        }
    }
`

export default Header
