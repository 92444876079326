import cookie from './cookie.svg'
import coverPicture from './cover_image_resized.png'
import licence_comp from './licence_comp.png'
import logoBVA from './logo_bva.png'
import reunion from './reunion.jpg'

const images = {
    logoBVA,
    coverPicture,
    cookie,
    reunion,
    licence_comp,
}

export default images
