import styled from '@emotion/styled'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
    return <CustomLoader animation="border" />
}

const CustomLoader = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: calc(50% - 100px);
    color: var(--primary-color);
    width: 100px;
    height: 100px;
    border-width: 10px;
`

export default Loader
