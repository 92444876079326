import { useLocation } from 'react-router-dom'

export function useLangSelected() {
    const location = useLocation()

    const lang = location.pathname
        .split('/')
        .filter((x, i) => i === 1)
        .join('/')

    return lang
}
