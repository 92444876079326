import styled from '@emotion/styled'

const Title = styled.div`
    font-size: 2.6rem;
    font-family: 'Roboto Semi Bold';
    color: var(--primary-color);
    margin-bottom: 24px;
`

export default Title
