import styled from '@emotion/styled'
import { Table as BootstrapTable } from 'react-bootstrap'
import Tr from './Tr'

type TableType = {
    tableHeader: string[]
    tableData: any[]
    deleteById?: (id: number) => void
    noDataMessage?: string
}

const Table = ({ tableHeader, tableData, deleteById, noDataMessage }: TableType) => {
    return (
        <BootstrapTable responsive>
            <thead>
                <tr>
                    {tableHeader.map((headerItem, index) => (
                        <th key={`th${index}`}>{headerItem}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {tableData.length > 0 ? (
                    tableData.map((dataItem, index) => (
                        <Tr key={`tr${index}`} deleteById={deleteById} dataItem={dataItem} id={dataItem.id} />
                    ))
                ) : (
                    <tr>
                        <CenterTd colSpan={tableHeader.length}>{noDataMessage || 'Aucune donnée trouvée'} </CenterTd>
                    </tr>
                )}
            </tbody>
        </BootstrapTable>
    )
}

const CenterTd = styled.td`
    text-align: center;
`

export default Table
