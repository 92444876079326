import styled from '@emotion/styled'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../Breakpoint'
import Container from '../../components/global/Container'
import Section from '../../components/global/Section'
import Title from '../../components/global/Title'

const Contact = () => {
    const { t } = useTranslation('translation')

    return (
        <Container>
            <Section>
                <Title>{t('page.contact.title')}</Title>
                <ContactInfoContainer>
                    <InfoContainer>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <InfoItem>contact@bva-club.fr</InfoItem>
                    </InfoContainer>
                    <InfoContainer>
                        <FontAwesomeIcon icon={faPhone} />
                        <img
                            alt=""
                            src="https://scontent-cdg4-2.xx.fbcdn.net/v/t39.30808-1/240824895_10220790694663244_2615772483876422545_n.jpg?stp=dst-jpg_s160x160&amp;_nc_cat=101&amp;ccb=1-7&amp;_nc_sid=0ecb9b&amp;_nc_ohc=wZM-493mF84Q7kNvgFc3j25&amp;_nc_ht=scontent-cdg4-2.xx&amp;_nc_gid=AEcU4LuHXgKi527-ebr4fsK&amp;oh=00_AYA0fFvwlZgiGJBBpMJ8KgKG9eSOTWZ5PQ0j81kwsgbbtw&amp;oe=66E7A4FD"
                        />
                        <TelWrapper>
                            Président (Melvin Cado) :&nbsp; <InfoItem>07 60 13 34 35</InfoItem>
                            <br />
                        </TelWrapper>
                    </InfoContainer>
                </ContactInfoContainer>
            </Section>
        </Container>
    )
}

const TelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ContactInfoContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: calc(100vh - 338px);

    @media (max-width: ${Breakpoint.TABLET_L}) {
        flex-direction: column;
        height: auto;
        margin-top: 16px;
    }
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;

    & div {
        font-family: 'Roboto Semi Bold';
        color: var(--primary-color);
        font-size: 2.2rem;
        margin-bottom: 8px;
    }

    & .fa-envelope,
    & .fa-phone {
        color: var(--primary-color);
        font-size: 6.2rem;
        margin-bottom: 16px;
    }

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-bottom: 32px;
        height: auto;
    }
`

const InfoItem = styled.div`
    font-family: 'Roboto Semi Bold';
    color: black !important;
`

export default Contact
