import { sweetAlertError } from '../sweetalert/Alert'

type Error = {
    status: number
    message: string
}

const catchError = ({ status, message }: Error, callback?: () => void) => {
    if (status === 401) {
        sweetAlertError(message).then((res) => {
            if (res.isConfirmed) {
                callback?.()
            }
        })
    } else {
        sweetAlertError(message).then().catch()
    }
}

export default catchError
