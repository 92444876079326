import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { PlanningData } from '../../types/planning.type'

const PlanningHeader = ({ planning }: { planning: PlanningData }) => {
    const { t } = useTranslation('translation')

    return (
        <Header>
            <HeaderItem></HeaderItem>
            {Object.entries(planning)
                .filter((x) => x[0] !== 'headerTime' && x[0] !== 'sunday')
                .map((day: [string, any], index: number) => (
                    <HeaderItem key={index}>{t(`global.${day[0]}`)}</HeaderItem>
                ))}
        </Header>
    )
}

const Header = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 8px 8px 0 0;
`

const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-right: 1px solid black;
    font-family: 'Roboto Semi Bold';
    font-size: 2rem;
    &:first-of-type {
        width: 50%;
        border-radius: 8px 0 0 0;
    }

    &:last-child {
        border: none;
        border-radius: 0 8px 0 0;
    }
`

export default PlanningHeader
