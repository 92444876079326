import styled from '@emotion/styled'
import { calculateReelTime } from '../../pages/admin/plannings/AdminPlanningsForm'
import { DtoSession, PlanningData } from '../../types/planning.type'

const PlanningContent = ({ planning }: { planning: PlanningData }) => {
    return (
        <Content>
            <ContentWrapper>
                {planning.headerTime.map((headerItem: number) => (
                    <ContentHeaderItem key={headerItem} start={headerItem} during={1} data={planning}>
                        {headerItem}h00 - {headerItem + 1}h00
                    </ContentHeaderItem>
                )) || []}
            </ContentWrapper>

            {Object.entries(planning)
                .filter((x) => x[0] !== 'headerTime' && x[0] !== 'sunday')
                .map((day: [string, any]) => (
                    <ContentWrapper key={day[0]}>
                        {day[1]?.map((session: DtoSession, index: number) => (
                            <ContentItem
                                key={index}
                                start={session.start}
                                during={session.during}
                                badmintonRoom={session.badmintonRoom}
                                size={session.size}
                                noBorderTop={session.noBorderTop}
                                borderLeft={session.borderLeft}
                                data={planning}
                            >
                                <span>
                                    {calculateReelTime(session.start).format('HH:mm')} -{' '}
                                    {calculateReelTime(session.end).format('HH:mm')}
                                </span>
                                <span>{session.name}</span>
                            </ContentItem>
                        )) || []}
                    </ContentWrapper>
                ))}
        </Content>
    )
}

type ContentHeaderItemProps = {
    size?: number
    start: number
    data: { headerTime: number[] }
    during: number
}

const ContentHeaderItem = styled.div`
    ${({ start, data, during }: ContentHeaderItemProps) => `
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        top: ${Number(((start - data.headerTime[0]) / data.headerTime.length) * 100)}%;
        height: ${Number((during / data.headerTime.length) * 100)}%;
        width: 100%;
        background-color: white;
        color: var(--primary-color);
        padding: 2%;
        box-sizing: border-box;
        border-bottom: 1px solid black;
        font-family: 'Roboto Semi Bold';
        font-size: 2rem;

        &:first-of-type {
            border-top: none;
        }

        &:last-child {
            border-radius: 0 0 0 8px;
            border-bottom: none;
        }
    `}
`

type ContentItemProps = {
    badmintonRoom: number
    size: number
    start: number
    data: { headerTime: number[] }
    during: number
    noBorderTop: boolean
    borderLeft: boolean
}

const ContentItem = styled.div`
    ${({ badmintonRoom, size, start, data, during, noBorderTop, borderLeft }: ContentItemProps) => `
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        left: ${(badmintonRoom - 1) * size}%;
        top: ${Number(((start - data.headerTime[0]) / data.headerTime.length) * 100)}%;
        height: ${Number((during / data.headerTime.length) * 100)}%;
        width: ${size ? size : 100}%;
        background-color: ${badmintonRoom === 1 ? 'var(--primary-color)' : 'white'};
        color: ${badmintonRoom === 1 ? 'white' : 'var(--primary-color)'};
        padding: 2%;
        box-sizing: border-box;
        border-top: ${noBorderTop ? 'none' : '1px solid black'};
        border-left: ${borderLeft ? '1px solid black' : 'none'};
        border-bottom: 1px solid black;
        overflow: hidden;
        font-size: 1.8rem;
    
        & span:last-child {
            margin-top: 8px;
            font-family: 'Roboto Semi Bold';
        }
    `}
`

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 600px;
    background-color: white;
    color: white;
    border-radius: 0 0 8px 8px;
`

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid black;

    &:first-of-type {
        width: 50%;
        border-radius: 0 0 0 8px;
    }

    &:last-child {
        border: none;
        border-radius: 0 0 8px 0;
    }
`

export default PlanningContent
