import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const MainLink = styled(Link)`
    padding: 8px 0;
    text-decoration: none;
    color: black;
    font-size: 2rem;
    transition: scale 0.4s;
    font-family: 'Roboto' !important;

    &:hover {
        color: var(--primary-color);
        scale: 1.2;
    }

    &.active {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
    }

    &:active {
        transform: scale(0.95);
    }
`

export default MainLink
