import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import CacheBuster from 'react-cache-buster'
import 'react-chatbot-kit/build/main.css'
import ReactDOM from 'react-dom'
import App from './App'
import Loader from './components/global/Loader'

const version = process.env.VERSION
const isProduction = process.env.NODE_ENV === 'production'

const pathname = window.location.pathname

if (pathname === '/') {
    window.location.assign('/fr/')
}

const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
        >
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </CacheBuster>
    </React.StrictMode>,
    document.getElementById('root')
)
