import axios from 'axios'
import { apiHeader, SERVER_URL } from '../config'

export const getAllUser = async () => {
    const tokenAdmin = localStorage.getItem('tokenAdmin')!
    return (await axios.get(`${SERVER_URL}/users`, apiHeader(tokenAdmin))).data
}

export const resetTournamentMoney = async (lng: string) => {
    const tokenAdmin = localStorage.getItem('tokenAdmin')!
    return await axios.post(`${SERVER_URL}/users/tournament-money/reset`, { lng }, apiHeader(tokenAdmin))
}

export const resetPassword = async (pseudo: string) => {
    return await axios.post(`${SERVER_URL}/users/reset-password`, { pseudo }, apiHeader())
}
