import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import gbTranslation from './languages/en/main'
import frTranslation from './languages/fr/main'
import ptTranslation from './languages/pt/main'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    fr: { translation: frTranslation() },
    gb: { translation: gbTranslation() },
    br: { translation: ptTranslation() },
}

const lang =
    window.location.pathname
        .split('/')
        .filter((x, i) => i === 1)
        .join('/') || 'fr'

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    })

