import styled from '@emotion/styled'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/global/Loader'
import News from '../../components/global/News'
import Title from '../../components/global/Title'
import { SERVER_URL, apiHeader } from '../../config'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { serverFile } from '../../services/file.service'
import { NewsModel } from '../../types/news.type'
import { UserModel } from '../../types/user.type'
import { CardContainer } from '../global/Home'

const MemberDashboard = () => {
    const user = useContext(UserContext)
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const [news, setNews] = useState<NewsModel[] | undefined>()
    const { t } = useTranslation('translation')

    const lng = useLangSelected()

    const loadNews = () =>
        axios.post(`${SERVER_URL}/news/private`, { lng }, apiHeader(user.tokenMember)).then((res) => setNews(res.data))

    useEffect(() => {
        if (!currentUser)
            axios
                .get(`${SERVER_URL}/users/${user.memberData.id}`, apiHeader(user.tokenMember))
                .then((res) => setCurrentUser(res.data))
    }, [])

    useEffect(() => {
        if (!news) loadNews()
    }, [news])

    useEffect(() => {
        loadNews()
    }, [lng])

    if (!currentUser) return <Loader />

    return (
        <>
            <Title>{t('member.dashboard')}</Title>
            <TextContainer>
                <p>
                    {t('member.welcome-1', {
                        user: {
                            firstname: currentUser.firstName,
                            lastname:
                                currentUser.lastName[0].toUpperCase() + currentUser.lastName.substring(1).toLowerCase(),
                        },
                    })}
                    <br />
                    {t('member.welcome-2')}
                    <br />
                    {t('member.welcome-3')}
                    <br />
                    {t('member.welcome-4')}
                    <br />
                    {t('member.welcome-5')}
                </p>
            </TextContainer>
            <Title> {t('member.last-news')}</Title>
            <CardContainer>
                {news ? (
                    news.map((news: NewsModel) => (
                        <News
                            key={news.id}
                            title={news.title}
                            type={news.type}
                            description={news.description}
                            image={serverFile(news.image)}
                            link={news.link}
                        />
                    ))
                ) : (
                    <Loader />
                )}
            </CardContainer>
        </>
    )
}

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background-color: white;
    margin-bottom: 16px;
`

export default MemberDashboard
