import styled from '@emotion/styled'

const CardLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    text-decoration: none;
    padding: 8px;
    height: 36px;
    border-radius: 4px;
    transition: all 0.4s;

    &:hover {
        opacity: 0.7;
        color: white;
    }
`

export default CardLink
