import styled from '@emotion/styled'

const Section = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    background: white;
    padding: 16px;
    border-radius: 6px;
    box-sizing: border-box;
`
export default Section
