import styled from '@emotion/styled'
import { ReactNode, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import AdminSideBar from './AdminSideBar'
import MemberSideBar from './MemberSideBar'

export const Main = ({ children }: { children: ReactNode }) => {
    const location = useLocation()

    let lng = useLangSelected()

    const user = useContext(UserContext)

    const isAdmin = location.pathname.includes('bva-admin') && location.pathname !== `/${lng}/bva-admin/login`
    const isMember = location.pathname.includes('bva-membre') && location.pathname !== `/${lng}/bva-membre/login`

    const chooseSideBar = (children: ReactNode) => {
        if (isAdmin && user.adminData && !location.pathname.includes('login'))
            return <AdminSideBar>{children}</AdminSideBar>
        else if (isMember && user.memberData && !location.pathname.includes('login'))
            return <MemberSideBar>{children}</MemberSideBar>
        return children
    }

    return <MainStyled isConnected={isAdmin || isMember}>{chooseSideBar(children)}</MainStyled>
}

const MainStyled = styled.main`
    ${({ isConnected }: { isConnected: boolean }) => isConnected && 'display: flex;'}
    min-height: ${({ isConnected }: { isConnected: boolean }) =>
        isConnected ? 'calc(100vh - 166px)' : 'calc(100vh - 58px)'};
    padding: 108px 0 0 0;
    z-index: 1;
`

export default Main
