import styled from '@emotion/styled'
import ReactHtmlParser from 'react-html-parser'
import Breakpoint from '../../Breakpoint'
import CardLink from './CardLink'

const Document = ({ title, description, link }: { title: string; description: string; link: string }) => {
    return (
        <CardContainer>
            <DocumentTitle>{title}</DocumentTitle>
            <DocumentDescription>{ReactHtmlParser(description)}</DocumentDescription>
            {link && (
                <CardLinkContainer>
                    <CardLink href={link} target="_blank">
                        Lire le document
                    </CardLink>
                </CardLinkContainer>
            )}
        </CardContainer>
    )
}

const DocumentTitle = styled.h2`
    font-size: 2.6rem;
    margin-top: 16px;
    margin-bottom: 8px;
    color: var(--primary-color);
    font-family: 'Roboto Semi Bold';
`

const DocumentDescription = styled.div`
    text-align: justify;
    line-height: 22px;
    min-height: 130px;
    overflow: auto;
    padding-right: 13px;
    margin-bottom: 5px;
`

export const CardContainer = styled.article`
    position: relative;
    width: 382px;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 16px;
    border-radius: 6px;
    margin-right: 5px;
    box-sizing: border-box;
    margin-bottom: 16px;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 0px 6px black;
    }

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        margin-bottom: 16px;
        width: 100%;
    }
`

const CardLinkContainer = styled.div`
    display: flex;
    text-decoration: none;
    border-radius: 4px;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
`

export default Document
