import model_adherents from './model_adherents.csv'
import model_compte_tournoi from './model_compte_tournoi.csv'
import model_renouvellement from './model_renouvellement.csv'

const files = {
    model_adherents,
    model_compte_tournoi,
    model_renouvellement,
}

export default files
