import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { DtoPlanning, PlanningType } from '../../../types/planning.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const displaySessions = (sessions: string | undefined) => {
    if (!sessions) return '0 séance'

    const sessionNumber = JSON.parse(sessions).length

    return `${sessionNumber > 1 ? sessionNumber + ' séances' : sessionNumber + ' séance'}`
}

const AdminPlannings = () => {
    const user = useContext(UserContext)
    const [plannings, setPlannings] = useState<PlanningType[] | undefined>(undefined)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const tableHeader = [
        '#',
        'Titre',
        'Heure début',
        'Heure fin',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche',
        '',
        '',
    ]

    useEffect(() => {
        verifyToken()
        if (!plannings || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/plannings/all`, { lng }, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setPlannings(
                        data.map((planning: DtoPlanning) => {
                            return {
                                id: planning.id,
                                name: planning.name,
                                minTime: (planning.minTime as string) + 'h',
                                maxTime: (planning.maxTime as string) + 'h',
                                monday: displaySessions(planning.monday),
                                tuesday: displaySessions(planning.tuesday),
                                wednesday: displaySessions(planning.wednesday),
                                thursday: displaySessions(planning.thursday),
                                friday: displaySessions(planning.friday),
                                saturday: displaySessions(planning.saturday),
                                sunday: displaySessions(planning.sunday),
                                edit: `/${lng}/bva-admin/plannings/${planning.id}`,
                                remove: true,
                            }
                        })
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [plannings, user, lng])

    if (!plannings) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/plannings/${id}`, apiHeader(user.tokenAdmin))
            .then(({ data }) => {
                sweetAlertSuccess('La suppression de cette compétition est réussi').then(() => {
                    setPlannings([...plannings.filter((planning) => planning.id !== data.id)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Plannings</Title>
                <LinkBtn to={`/${lng}/bva-admin/plannings/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={plannings} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}
export default AdminPlannings
