import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { Form } from 'react-bootstrap'

const Label = ({ children }: { children: ReactNode }) => <FormLabel>{children}</FormLabel>

const FormLabel = styled(Form.Label)`
    color: black;
`

export default Label
