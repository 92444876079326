import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { CompetitionModel } from '../../../types/competition.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const AdminCompetitions = () => {
    const user = useContext(UserContext)
    const [competitions, setCompetitions] = useState<CompetitionModel[] | undefined>(undefined)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const tableHeader = [
        '#',
        'Titre',
        'Sous-titre',
        'Description',
        'Image',
        'Lien BadNet',
        'Lien HelloAsso',
        'prix minimum',
        '',
        '',
    ]

    useEffect(() => {
        verifyToken()
        if (!competitions || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/competitions/all`, { lng }, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setCompetitions(
                        data.map((competition: CompetitionModel) => ({
                            id: competition.id,
                            title: competition.title,
                            subtitle: competition.subtitle,
                            description: ReactHtmlParser(competition.description),
                            image: competition.image ? (competition.image ? serverFile(competition.image) : '') : '',
                            linkBadnet: competition.linkBadnet,
                            linkHelloAsso: competition.linkHelloAsso,
                            minPrice: competition.minPrice,
                            edit: `/${lng}/bva-admin/competitions/${competition.id}`,
                            remove: true,
                        }))
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [competitions, user, lng])

    if (!competitions) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/competitions/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de cette compétition est réussi').then(() => {
                    setCompetitions([...competitions.filter((competition) => competition.id !== res.data.id)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }
    return (
        <>
            <TitleWrapper>
                <Title>Compétitions</Title>
                <LinkBtn to={`/${lng}/bva-admin/competitions/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={competitions} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export default AdminCompetitions
