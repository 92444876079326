export type NewsModel = {
    id: number
    title: string
    type: NewsType
    description: string
    image: string
    link: string
}

export enum NewsType {
    MEETING = 'MEETING',
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
}

export const helperNewsType = (typeEnum: NewsType) => {
    if (typeEnum === NewsType.MEETING) return 'Réunion'
    if (typeEnum === NewsType.PRIVATE) return 'Privée'
    return 'Publique'
}
