import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from '../../api/admin-user.api'
import Breakpoint from '../../Breakpoint'
import Label from '../../components/form/Label'
import catchError from '../../components/global/CatchError'
import Title from '../../components/global/Title'
import { sweetAlertSuccess } from '../../components/sweetalert/Alert'
import { SERVER_URL } from '../../config'
import UserContext from '../../context/UserContext'
import { useLangSelected } from '../../hooks/useLangSelected'
import { DecodedToken, roles } from '../../types/user.type'
import { AdminForm } from './users/AdminUsersForm'

const Login = () => {
    const [userConnect, setUserConnect] = useState('')
    const [password, setPassword] = useState('')
    const user = useContext(UserContext)

    const navigate = useNavigate()

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [pseudo, setPseudo] = useState('')

    const lng = useLangSelected()

    const { t } = useTranslation('translation')
    const location = useLocation()

    const { mutate } = useMutation({
        mutationFn: (pseudo: string) => resetPassword(pseudo),
    })

    const login = () => {
        axios
            .post(`${SERVER_URL}/users/login`, { userConnect, password })
            .then((res) => {
                const tokenDecoded: DecodedToken = jwtDecode(res.data)

                if (tokenDecoded.role === roles.ROLE_ADMIN) {
                    localStorage.setItem('tokenAdmin', 'Bearer ' + res.data)
                    user.tokenAdmin = localStorage.getItem('tokenAdmin') ?? ''
                    user.adminData = tokenDecoded

                    sweetAlertSuccess('Connexion réussi, cliquez sur OK pour être redirigé').then(() => {
                        navigate(`/${lng}/bva-admin/login`)
                        window.location.reload()
                    })
                } else {
                    localStorage.setItem('tokenMember', 'Bearer ' + res.data)
                    user.tokenMember = localStorage.getItem('tokenMember') ?? ''
                    user.memberData = tokenDecoded

                    sweetAlertSuccess('Connexion réussi, cliquez sur OK pour être redirigé').then(() => {
                        navigate(`/${lng}/bva-membre/login`)
                        window.location.reload()
                    })
                }
            })
            .catch((err) => {
                catchError(err.response.data)
            })
    }

    return (
        <LoginFormContainer>
            {showResetPasswordModal && (
                <Modal centered show={true} onHide={() => setShowResetPasswordModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Réinitialiser son mot de passe
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AdminForm>
                            <Form.Group className="mb-3">
                                <Label>Pseudo * (reçu par mail)</Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Pseudo *"
                                    onChange={(e) => setPseudo(e.target.value)}
                                    value={pseudo}
                                />
                            </Form.Group>
                            <StyledButton
                                variant="primary"
                                type="button"
                                disabled={!pseudo}
                                onClick={() => {
                                    mutate(pseudo)
                                    setPseudo('')
                                    setShowResetPasswordModal(false)
                                    sweetAlertSuccess('Un email à été envoyé au pseudo associé.')
                                }}
                            >
                                Envoyer le mail
                            </StyledButton>
                        </AdminForm>
                    </Modal.Body>
                </Modal>
            )}
            <LoginFormWrapper>
                <Title>
                    {t('page.login.connexion')}{' '}
                    {location.pathname.includes('bva-membre') ? t('page.login.member') : t('page.login.admin')}
                </Title>
                <LoginForm>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Label>Email ou Pseudo</Label>
                        <Form.Control
                            type="text"
                            placeholder="Entre ton email ou pseudo"
                            onChange={(e) => setUserConnect(e.target.value)}
                            value={userConnect}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Label>Mot de passe</Label>
                        <Form.Control
                            type="password"
                            placeholder="Entre ton mot de passe"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </Form.Group>
                    <StyledLinkAByButton variant="link" type="button" onClick={() => setShowResetPasswordModal(true)}>
                        Mot de passe oublié ?
                    </StyledLinkAByButton>
                    <StyledButton
                        variant="primary"
                        type="button"
                        disabled={!userConnect || !password}
                        onClick={() => login()}
                    >
                        Se connecter
                    </StyledButton>
                </LoginForm>
            </LoginFormWrapper>
        </LoginFormContainer>
    )
}

const StyledLinkAByButton = styled(Button)`
    display: flex;
    margin: 8px 0;
    padding: 0;
    font-size: 1.6rem;
`

export const StyledButton = styled(Button)`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const LoginForm = styled(Form)`
    width: 100%;

    input {
        font-size: 1.6rem;
    }
`

const LoginFormContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 166px);
    justify-content: center;
    align-items: center;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        padding: 16px;
    }
`

const LoginFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    border-radius: 6px;
    padding: 24px;
    background-color: white;
    justify-content: center;
    align-items: center;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        width: 100%;
    }
`

export default Login
