import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CompetitionEvent from '../../components/global/CompetitionEvent'
import Container from '../../components/global/Container'
import Loader from '../../components/global/Loader'
import Title from '../../components/global/Title'
import { SERVER_URL } from '../../config'
import { useLangSelected } from '../../hooks/useLangSelected'
import { serverFile } from '../../services/file.service'
import { CompetitionModel } from '../../types/competition.type'

const Competitions = () => {
    const [competitions, setCompetitions] = useState<CompetitionModel[] | undefined>(undefined)

    const { t } = useTranslation('translation')

    const lng = useLangSelected()

    const loadCompetitions = () =>
        axios.post(`${SERVER_URL}/competitions/all`, { lng }).then((res) => {
            setCompetitions(res.data)
        })

    useEffect(() => {
        if (!competitions) {
            loadCompetitions()
        }
    }, [competitions])

    useEffect(() => {
        loadCompetitions()
    }, [lng])

    if (!competitions) return <Loader />

    return (
        <Container>
            <Title>{t('page.competition.title')}</Title>
            {competitions.length > 0 ? (
                competitions.map((competition: CompetitionModel) => (
                    <CompetitionEvent
                        key={competition.id}
                        title={competition.title}
                        subtitle={competition.subtitle}
                        description={competition.description}
                        image={serverFile(competition.image)}
                        linkBadnet={competition.linkBadnet}
                        linkHelloAsso={competition.linkHelloAsso}
                        minPrice={competition.minPrice}
                        limitDateHelloAsso={competition.limitDateHelloAsso}
                    />
                ))
            ) : (
                <div>{t('page.competition.no-data')} </div>
            )}
        </Container>
    )
}

export default Competitions
