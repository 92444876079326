import styled from '@emotion/styled'
import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'

const SearchInput = ({
    value,
    onChange,
    placeholder,
}: {
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    placeholder: string
}) => {
    return (
        <SearchInputContainer>
            <Form.Group className="mb-3">
                <Form.Control placeholder={placeholder} onChange={onChange} value={value} title={placeholder} />
            </Form.Group>
        </SearchInputContainer>
    )
}

const SearchInputContainer = styled.div`
    display: flex;
    margin-bottom: 16px;

    .form-control {
        font-size: 16px;
        width: min-content;
    }
`

export default SearchInput
