export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const apiHeader = (token?: boolean | string, isFormData?: boolean): any => {
    if (token)
        return {
            headers: { Authorization: token, 'content-type': isFormData ? 'multipart/form-data' : 'application/json' },
        }

    return { headers: { Authorization: {} } }
}
