import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllAdminDocuments } from '../../../api/documentation.api'
import Document from '../../../components/global/Document'
import Loader from '../../../components/global/Loader'
import SearchInput from '../../../components/global/SearchInput'
import Title from '../../../components/global/Title'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { DocumentModel } from '../../../types/document.type'
import { CardContainer } from '../../global/Home'

const AdminToDocumentMyself = () => {
    const { t } = useTranslation('translation')

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const [searchTerm, setSearchTerm] = useState('')
    const [filterDocuments, setFilterDocuments] = useState<DocumentModel[]>()

    const { data: documents } = useQuery({
        queryKey: ['document-myself-admin-all'],
        queryFn: () => {
            verifyToken()
            return getAllAdminDocuments(lng)
        },
    })

    useEffect(() => {
        setFilterDocuments(
            documents?.filter((doc) => doc.title.toLowerCase().includes(searchTerm.toLowerCase())) as DocumentModel[]
        )
    }, [searchTerm, documents])

    if (!documents) return <Loader />

    return (
        <>
            <Title>{t('sidebar.admin.to-document-myself')}</Title>
            <SearchInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('member.search-document-input')}
            />
            <CardContainer>
                {filterDocuments?.map((document: DocumentModel) => (
                    <Document
                        key={document.id}
                        title={document.title}
                        description={document.description}
                        link={document.file}
                    />
                )) ?? []}
            </CardContainer>
        </>
    )
}

export default AdminToDocumentMyself
