const gbTranslation = () => {
    return {
        global: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
            lang: {
                fr: 'French',
                gb: 'English',
                br: 'Brazil',
            },
        },
        home: {
            'main-title': "Badminton Villeneuve d'Ascq",
            'presentation-1': 'Youngs and adults, competitors et leisure, ',
            'presentation-2': 'the ',
            'presentation-3': 'BVA',
            'presentation-4': ' the BVA supports all badminton players to have fun.',
            'last-news': 'Last news',
        },
        header: {
            menu: {
                home: 'Home',
                planning: 'Planning & Prices',
                registration: 'Sign up',
                competition: 'Tournaments',
                contact: 'Contact us',
                'my-space-bva': 'My BVA space',
                login: 'Sign in',
            },
            'my-tournament-account': 'My tournament account',
            'tournament-account-message':
                "The tournament account starts at 0€ and can reach a maximum of 25€. It only concerns the competitors. It's easy to fill in: just take part in training sessions and let us know if you are be absent.",
        },
        footer: {
            copyright: 'Copyright © 2022. All rights reserved - Site created by',
            administration: 'Administration',
        },
        sidebar: {
            admin: {
                dashboard: 'Dashboard',
                user: 'User',
                news: 'News',
                competition: 'Tournaments',
                plannings: 'Plannings',
                prices: 'Prices',
                documentation: 'Documentation',
                'to-document-myself': 'To document myself',
                'bva-bot': 'BVA bot',
                password: 'Password',
            },
        },
        admin: {
            dashboard: 'Dashboard',
            or: 'or',
            'search-member-input': '🔍 Search a member',
        },
        member: {
            dashboard: 'Dashboard',
            documentation: 'Documentation',
            'search-document-input': '🔍 Search a document',
            'welcome-1': 'Hello {{ user.firstname }} {{ user.lastname }},',
            'welcome-2': 'Welcome to your BVA account',
            'welcome-3': 'This is where your tournament account is displayed (useful for the Competitor License).',
            'welcome-4': 'In the future, new features may be introduced.',
            'welcome-5':
                "If you have any ideas on how to improve the website, don't hesitate to send them to the following e-mail address: president@bva-club.fr.",
            'last-news': 'Latest news',
            'change-password-title': 'Change password',
            'current-password-label': 'Current password',
            'new-password-label': 'New password',
            'confirm-password-label': 'Confirm the new mot de passe',
        },
        error: {
            'session-expired': 'Your session has expired, you will be disconnected for security reasons.',
        },
        page: {
            planning: {
                'summer-sessions':
                    "Summer sessions are open to everyone during the summer holidays. It's a good time to come and try out and have some fun with us on the courts.",
            },
            contact: {
                title: 'Contact us',
            },
            competition: {
                title: 'Tournaments',
                'badnet-register': 'Sing up on BadNet',
                'tournament-account-register': 'Sing up with tournament account',
                'need-login': 'To use your tournament account you have to log in',
                'no-data': 'No tournaments yet. We will add them shortly.',
            },
            price: {
                title: 'Prices for the 2024/2025 season',
                'title-try': 'Try-out sessions',
                'price-try-1': 'Before signing up, you are allowed 3 try-out sessions.',
                'price-try-2': 'We can lend you rackets and shuttlecocks for these occasions.',
                'price-try-3': "Beyond that, you'll need to bring your own rackets and shuttlecocks.",
                'register-info-date':
                    'Registrations will open on 1 August for renewals of existing members. And from 2 September 2024 for new members.',
                'register-info-competition': 'All our licences allow you to take part in competitions.',
                'warning-off-day': '⚠️ Please note: The club is always closed on public holidays ! ⚠️',
            },
            login: {
                connexion: 'Login',
                member: 'member',
                admin: 'administrateur',
            },
        },
        bot: {
            init: 'Hello! What can I do for you? You can ask me short questions or use keywords. For example, the word "schedules" will redirect you to the schedules and prices section. For longer messages, please contact the BVA at the following e-mail address: contact@bva-club.fr',
        },
        cookies:
            'This website uses cookies that last only one day in order to measure the number of visitors to improve the use of this site and to prove our visitor numbers to our partners.',
    }
}

export default gbTranslation
