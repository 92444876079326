import styled from '@emotion/styled'
import axios from 'axios'
import JoditEditor from 'jodit-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { ReductionFormType } from '../../../types/price.type'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const AdminPriceForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/tarifs`

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState<number>(0)
    const [midSeasonPrice, setMidSeasonPrice] = useState<number>(0)
    const [reductions, setReductions] = useState<ReductionFormType[]>([])

    const editor = useRef(null)

    const [reductionFormModal, setReductionFormModal] = useState<ReductionFormType | undefined>(undefined)

    const [isLoading, setIsLoading] = useState(true)

    const isAddForm = location.pathname.includes('/bva-admin/tarifs/creer')

    useEffect(() => {
        verifyToken()
        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/prices/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then((res) => {
                    setName(res.data.name)
                    setDescription(res.data.description)
                    setPrice(res.data.price)
                    setMidSeasonPrice(res.data.midSeasonPrice)
                    setReductions(res.data.reductions)
                    setIsLoading(false)
                })
                .catch((err) => catchError(err.response.data))
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id, lng])

    if (isLoading) return <Loader />

    const isValidate = name && description && price && midSeasonPrice

    const submit = () => {
        verifyToken()
        if (isAddForm) {
            const data = {
                name,
                description,
                price,
                midSeasonPrice,
                reductions,
            }

            axios
                .post(`${SERVER_URL}/prices`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le tarif a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            const data = {
                name,
                description,
                price,
                midSeasonPrice,
                reductions,
                lng,
            }

            axios
                .patch(`${SERVER_URL}/prices/${params.id}`, data, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le tarif a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un tarif</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Titre du tarif*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Titre du tarif*"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Description *</Label>
                        <JoditEditor
                            ref={editor}
                            // config={config}
                            value={description}
                            onBlur={(newContent) => setDescription(newContent)}
                            onChange={(newContent) => setDescription(newContent)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Prix*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Prix*"
                            onChange={(e) => setPrice(+e.target.value)}
                            value={price}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Prix de mi-saison*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Prix de mi-saison*"
                            onChange={(e) => setMidSeasonPrice(+e.target.value)}
                            value={midSeasonPrice}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Label>Réduction</Label>
                        <Test
                            variant="primary"
                            type="button"
                            onClick={() => setReductionFormModal({ priceReduction: 0 })}
                        >
                            Ajouter une réduction
                        </Test>
                        <Table
                            tableHeader={['Nom', 'Montant de la réduction']}
                            tableData={
                                reductions.map((reduction: ReductionFormType, index: number) => ({
                                    name: reduction.name,
                                    priceReduction: reduction.priceReduction,
                                    edit: () =>
                                        setReductionFormModal({
                                            ...reductionFormModal,
                                            name: reduction.name,
                                            priceReduction: reduction.priceReduction,
                                            index,
                                        }),
                                    remove: () => setReductions(reductions.filter((_r, idx) => index !== idx)),
                                })) || []
                            }
                            noDataMessage="Aucun réduction enregistré"
                        />
                    </Form.Group>
                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>
            </AdminContainer>

            {reductionFormModal && (
                <Modal centered show={true} onHide={() => setReductionFormModal(undefined)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">Ajouter une réduction</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Label>Titre de la réduction*</Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Titre de la réduction*"
                                    onChange={(e) =>
                                        setReductionFormModal({ ...reductionFormModal, name: e.target.value })
                                    }
                                    value={reductionFormModal.name ?? ''}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Label>Montant de la réduction*</Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Montant de la réduction*"
                                    onChange={(e) =>
                                        setReductionFormModal({
                                            ...reductionFormModal,
                                            priceReduction: +e.target.value,
                                        })
                                    }
                                    value={reductionFormModal.priceReduction ?? ''}
                                />
                            </Form.Group>
                            <StyledButton
                                variant="primary"
                                type="button"
                                disabled={!reductionFormModal.name || !reductionFormModal.priceReduction}
                                onClick={() => {
                                    if (reductionFormModal.index === undefined)
                                        setReductions([
                                            ...reductions,
                                            {
                                                name: reductionFormModal.name,
                                                priceReduction: reductionFormModal.priceReduction,
                                            },
                                        ])
                                    else
                                        setReductions([
                                            ...reductions.map((r, idx) =>
                                                idx === reductionFormModal.index
                                                    ? {
                                                          name: reductionFormModal.name,
                                                          priceReduction: reductionFormModal.priceReduction,
                                                      }
                                                    : r
                                            ),
                                        ])
                                    setReductionFormModal(undefined)
                                }}
                            >
                                Enregistrer la réduction
                            </StyledButton>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

const Test = styled(StyledButton)`
    margin-left: 8px;
`

export default AdminPriceForm
