export type UserModel = {
    id: number
    pseudo: string
    role: string
    email: string
    connectionAttempt?: number
    firstName: string
    lastName: string
    dayOfBirth: string
    gender: string
    licenseNumber: string
    tel: string
    tournamentMoney: number
}

export type UserContextType = {
    tokenAdmin: string
    tokenMember: string
    adminData: any
    memberData: any
}

type Role = 'ROLE_MEMBER' | 'ROLE_ADMIN'

export type DecodedToken = {
    id: number
    role: Role
}

export const roles = {
    ROLE_MEMBER: 'ROLE_MEMBER',
    ROLE_ADMIN: 'ROLE_ADMIN',
}
